import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['updateButton', 'alert'];
  changedItems = [];

  connect() {
    super.connect();
  }

  disconnect() {
    super.disconnect();
  }

  refreshUpdateButton(blankId) {
    const item = this.changedItems.find(item => item.blank_id === blankId);
    const disableButton = Object.values(item).includes('');

    this.setUpdateButtonDisabled(item.blank_id, disableButton);
  }

  setUpdateButtonDisabled(blankId, disabled) {
    this.updateButtonTargets.find(
      element => element.dataset.blankId === blankId
    ).disabled = disabled;
  }

  changeBlankLeadTime({ currentTarget }) {
    const { blankId } = currentTarget.dataset;
    const itemIndex = this.findBlankIndex(blankId);

    if (itemIndex === -1) {
      this.changedItems.push({
        blank_id: blankId,
        blank_lead_time_in_days: currentTarget.value
      });
    } else {
      const item = this.changedItems[itemIndex];
      item.blank_lead_time_in_days = currentTarget.value;
      this.changedItems[itemIndex] = item;
    }

    this.refreshUpdateButton(blankId);
  }

  changeFGLeadTime({ currentTarget }) {
    const { blankId } = currentTarget.dataset;
    const itemIndex = this.findBlankIndex(blankId);

    if (itemIndex === -1) {
      this.changedItems.push({
        blank_id: blankId,
        finished_good_lead_time_in_days: currentTarget.value
      });
    } else {
      const item = this.changedItems[itemIndex];
      item.finished_good_lead_time_in_days = currentTarget.value;
      this.changedItems[itemIndex] = item;
    }

    this.refreshUpdateButton(blankId);
  }

  changeVisibility({ currentTarget }) {
    const { blankId } = currentTarget.dataset;
    const itemIndex = this.findBlankIndex(blankId);

    if (itemIndex === -1) {
      this.changedItems.push({
        blank_id: blankId,
        visible_in_store: currentTarget.checked
      });
    } else {
      const item = this.changedItems[itemIndex];
      item.visible_in_store = currentTarget.checked;
      this.changedItems[itemIndex] = item;
    }

    this.refreshUpdateButton(blankId);
  }

  findBlankIndex(blankId) {
    return this.changedItems.findIndex(item => item.blank_id === blankId);
  }

  updateMasterBlank({ currentTarget }) {
    const { blankId } = currentTarget.dataset;
    const blankIdentifier = `${currentTarget.dataset.vendorSku}-${currentTarget.dataset.vendorSize}`;

    const blankIndex = this.changedItems.findIndex(
      element => element.blank_id === blankId
    );
    const blank_params = this.changedItems[blankIndex];
    delete blank_params.blank_id;

    if (blankIndex === -1) return;

    $.ajax({
      url: `/manage/sanmar_inventories/master_blanks/${blankId}`,
      data: { master_blank: blank_params },
      method: 'PATCH',
      success: () => {
        console.log('success');
        this.changedItems.splice(blankIndex, 1);
        this.setUpdateButtonDisabled(blankId, true);
        this.showSuccessMessage(blankIdentifier);
      },
      error: data => {
        console.log(data);
        this.showErrorMessage(blankIdentifier);
      }
    });
  }

  showSuccessMessage(item_identifier) {
    this.alertTarget.classList.remove('d-none');
    if (this.alertTarget.classList.contains('alert-danger')) {
      this.changeAlertType('success');
    }
    this.alertTarget.innerHTML = `Item <b>${item_identifier}</b> was updated successfully.`;
  }

  showErrorMessage(item_identifier) {
    this.alertTarget.classList.remove('d-none');
    if (this.alertTarget.classList.contains('alert-success')) {
      this.changeAlertType('danger');
    }
    this.alertTarget.innerHTML = `Something went wrong while updating item <b>${item_identifier}</b>. Update was aborted.`;
  }

  changeAlertType(type) {
    if (type === 'success') {
      this.alertTarget.classList.add('alert-success');
      this.alertTarget.classList.remove('alert-danger');
    } else {
      this.alertTarget.classList.add('alert-danger');
      this.alertTarget.classList.remove('alert-success');
    }
  }
}
